import { AltLayout } from "layouts/AltLayout";
import React from "react";
import { AboutPageContainer } from "./about";
import AboutSection from "./components/about-section";
import AboutUsLeaves from "./components/about-us-leaves";
import { DoctorData } from "constants/doctor-intro";
import DoctorIntro from "@/common/doctor-intro";
import { theme } from "@/GlobalStyles";
import AlternativeHeroBanner from "@/common/alternative-hero-banner";

const AboutPage = () => {
  return (
    <AboutPageContainer>
      <AltLayout>
        <AlternativeHeroBanner
          PageName="About Us"
          NavigateFrom="Home"
          NavigateTo="About Us"
          IntroHeader="Hello There."
          BgHero="about-us-hero.jpg"
          introPara="Dr. Sam and Dr. Blerta are committed to providing personalized and
          compassionate care to create healthy and happy smiles!"
        ></AlternativeHeroBanner>
        <AboutSection />
        <AboutUsLeaves />
        {DoctorData.map((item, ind) => (
          <DoctorIntro
            key={ind}
            image={item.image}
            aapdlogo={item.dlogo}
            drName={item.drName}
            drPost={item.drPost}
            certifications={item.certifications}
            quote={item.quote}
            description={item.description}
            buttonTitle={item.buttonTitle}
            crown={item.crown}
            flexDirection={item.flexDirection}
            backgroundColor={theme.colors.white}
            link={item.link}
            animation={item.animation}
          />
        ))}
        {/* <Gallery /> */}
      </AltLayout>
    </AboutPageContainer>
  );
};

export default AboutPage;
