import Image from "@/common/image";
import React from "react";
import { ImageWrapper } from "./about-us-leaves";

const AboutUsLeaves = () => {
  return (
    <ImageWrapper>
      <Image filename="about-us-2.jpg" alt="about us leaves" />
    </ImageWrapper>
  );
};

export default AboutUsLeaves;
