export const DoctorData = [
  {
    image: "dr-sam.png",
    flexDirection: "row",
    drName: "DR. SAM",
    drPost: "Pediatric Dentist",
    dlogo: "aapd_logo.png",
    certifications: true,
    quote:
      "Dr. Sam’s favorite part of pediatric dentistry is the energy and FUN  kids bring to the office every day.",
    description:
      "Dr. Sam has wanted to be a dentist since she was in middle school.  She has always been one of those weirdos that love teeth, so dentistry was an easy decision! She was born and raised in a small town near Buffalo, NY and didn't even know pediatric dentists existed until applying for dental school. After just a few rotations in dental school working with children and patients with special needs, Dr.Sam knew she was going to specialize in pediatric dentistry. Dr. Sam’s favorite part of pediatric dentistry is the energy and FUN  kids bring to the office every day. There’s never a dull moment at work and she loves getting to laugh with the kiddos and build long lasting relationships with them and their families!",
    buttonTitle: "Read More About Dr Sam",
    crown: true,
    link: "/doctors/dr-sam",
    animation: "fade-right",
  },
  {
    image: "dr-blerta.png",
    flexDirection: "row-reverse",
    dlogo: "aao_logo.png",
    drName: "DR. BLERTA",
    drPost: "Orthodontist",
    certifications: true,
    quote:
      "Dr. Blerta loves to make people smile. Literally. When your family visits the office, you will be told a joke. You will be expected to laugh. Don’t worry, we’re just kidding… Or are we?",
    description:
      "Dr. Blerta was born and raised in the small country of Albania. She moved with her family to the United States in 2008. After residency, she moved to Nashville and has been calling herself a proud Nashvillian ever since. Her favorite part about practicing orthodontics is getting to know the patients and their families. She loves to witness the confidence that orthodontic patients (young and not so young) gain after completing their treatment! When she is not in the office Dr. Blerta likes to spend time with her husband, Erik (who loves spreadsheets), daughter, Anna (who loves bounce houses) and her 2 mini labradoodles Ove and Alfie (who love treats). She loves reading, trying new restaurants and napping.",
    buttonTitle: "Read More About Dr Blerta",
    crown: false,
    link: "/doctors/dr-blerta",
    animation: "fade-left",
  },
];
