import styled from "@emotion/styled";
import { mq } from "styles/media-query";

export const ImageWrapper = styled.div`
  max-height: 480px;
  height: 100%;
  width: 100%;
  margin-bottom: 20px;

  ${mq["md"]} {
    margin-top: 20px;
  }
`;
